import React, { useState } from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ReserveButton from './ReserveButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  viewMenuButton,
} from 'images'
import MenuModal from './MenuModal'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView
            image={`url(${mobileHeroText}) center / contain no-repeat`}
            mh="3vw"
            h="100%"
            column
            justifyEnd
            alignCenter
          >
            <CFView mb="13vh">
              <CFView mb="8px" pulsate>
                <OrderPickupButton />
              </CFView>
              <ReserveButton />
              <CFView
                hover
                onClick={() => setShowModal(true)}
                column
                center
                mt="8px"
                mb="15px"
              >
                <CFImage src={viewMenuButton} maxWidth="77vw" alt="About" />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
        >
          <Header />
          <CFView column center mt="90px">
            <CFImage
              src={heroText}
              maxWidth="800px"
              maxHeight="70vh"
              alt="About"
            />
            <CFView relative bottom="32vh" textCenter>
              <CFView pulsate mb="10px">
                <OrderPickupButton />
              </CFView>
              <ReserveButton />
              <CFView
                hover
                onClick={() => setShowModal(true)}
                column
                center
                mt="10px"
              >
                <CFImage
                  src={viewMenuButton}
                  w="98%"
                  maxHeight="6.9vh"
                  alt="About"
                />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MenuModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
