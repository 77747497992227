import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/422xJ4j9vT64RHhTWYLI/locations/a48Vbjw5aUf8ra63ePT5`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={orderPickupButton} maxWidth="77vw" alt="About" />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/422xJ4j9vT64RHhTWYLI/locations/a48Vbjw5aUf8ra63ePT5`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={orderPickupButton} maxHeight="7vh" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
