import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/The-Hide-Out-Diner-100719618127896">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="The Hide Out Diner Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/the-hide-out-diner-vancouver">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="The Hide Out Diner Yelp"
              />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/amigo-cafe-port-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="The Hide Out Diner Zomato"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/thehideoutdiner/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="The Hide Out Diner Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/The-Hide-Out-Diner-100719618127896">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="The Hide Out Diner Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/the-hide-out-diner-vancouver">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="The Hide Out Diner Yelp"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/amigo-cafe-port-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="The Hide Out Diner Zomato"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/thehideoutdiner/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="The Hide Out Diner Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
