import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <Parallax
            bgImage={mobileAbout}
            bgImageAlt="Entree Dish"
            strength={300}
          >
            <div style={{ height: '400px' }} />
          </Parallax>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          alignCenter
          pv="10%"
          pr="40px"
          h="600px"
          maxWidth="1400px"
          image={`url(${about}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
